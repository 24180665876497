const fetch = require('node-fetch')

const deactivateJwt = async () => {
  return await await fetch(
    `${process.env.GATSBY_CMS_URL}/wp-json/ecoride/v1/deactivate-jwt`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
}

const activateJwt = async () => {
  return await await fetch(
    `${process.env.GATSBY_CMS_URL}/wp-json/ecoride/v1/activate-jwt`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
}

module.exports = {
  deactivateJwt,
  activateJwt,
}
