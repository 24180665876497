import React, { useState, useRef } from 'react'
import { navigate } from 'gatsby'
import { searchCustomer, registerCustomer } from '../../services/auth'
import { useTranslation } from '../../hooks/useTranslation'
import { useResponsive } from '../../hooks/useResponsive'
import { COLORS, SPACING } from '../../helpers/constants'
import { formatErrors } from '../../helpers/texts'
import { colors } from '../../styles/utilities/variables'
import { validateField } from '../../helpers/utilities'

import { Heading } from '../Heading'
import { Divider } from '../Divider'
import { Button } from '../Button'
import { Form } from '../Form'
import { StyledGrid, PageContent, Content } from './style'
import { TextLink } from '../TextLink'
import { Loader } from '../Svg'

const Register = () => {
  const [searching, setSearching] = useState(false)
  const [create, setCreate] = useState(false)

  const [hasOrder, setHasOrder] = useState(false)
  const [orderNotFound, setOrderNotFound] = useState(false)
  const [registerError, setRegisterError] = useState('')

  const getTranslation = useTranslation()

  const responsive = useResponsive()
  const maxBtnWidth = responsive?.windowSize?.width < 768 ? '100%' : '350px'

  const [searchUserForm, setSearchUserForm] = useState({
    email: '',
    order: '',
    valid: false,
  })

  const [registerForm, setRegisterForm] = useState({
    email: searchUserForm.email,
    orders: '',
    password: '',
    repeatpassword: '',
    username: '',
    valid: false,
  })

  const handleCustomerSearch = e => {
    e.preventDefault()
    setSearching(true)
    searchCustomer(searchUserForm.order, searchUserForm.email)
      .then(response => {
        if (response.errors) {
          setOrderNotFound(formatErrors(response))
        } else {
          const orders = response.map(order => order.ID)
          if (orders.includes(searchUserForm.order)) {
            let regForm = registerForm
            regForm.orders = orders.join(',')
            regForm.email = searchUserForm.email

            setRegisterForm(regForm)
            setHasOrder(true)
          }
        }
        setSearching(false)
      })
      .catch(err => {
        setOrderNotFound(true)
        setSearching(false)
      })
  }

  const updateEmail = e => {
    var userEmail = e.target.value
    var valid = validateField('email', userEmail)

    if (!valid) {
      searchOrderForm[0].innerRef.current.style.borderColor = colors.primaryRed
    } else {
      searchOrderForm[0].innerRef.current.style.borderColor = colors.borderColor
    }

    setSearchUserForm({
      email: userEmail,
      order: searchUserForm.order,
      valid: valid,
    })
  }

  const updateOrder = e => {
    var order = e.target.value
    var valid = validateField('value', order)

    if (!valid) {
      searchOrderForm[1].innerRef.current.style.borderColor = colors.primaryRed
    } else {
      searchOrderForm[1].innerRef.current.style.borderColor = colors.borderColor
    }

    setSearchUserForm({
      order: order,
      email: searchUserForm.email,
      valid: valid,
    })
  }

  const updateRepeatPassword = e => {
    var repassword = e.target.value
    var password = registerForm.password
    var valid = validateField('min6', repassword)

    if (!valid && !(repassword === password)) {
      registerCustomerForm[2].innerRef.current.style.borderColor =
        colors.primaryRed
    } else {
      registerCustomerForm[2].innerRef.current.style.borderColor =
        colors.borderColor
    }

    setRegisterForm({
      ...registerForm,
      repeatpassword: repassword,
      valid: valid,
    })
  }

  const updatePassword = e => {
    var password = e.target.value
    var valid = validateField('min6', password)

    if (!valid) {
      registerCustomerForm[1].innerRef.current.style.borderColor =
        colors.primaryRed
    } else {
      registerCustomerForm[1].innerRef.current.style.borderColor =
        colors.borderColor
    }

    setRegisterForm({ ...registerForm, password: password, valid: valid })
  }

  const updateUsername = e => {
    var username = e.target.value
    var valid = validateField('value', username)

    if (!valid) {
      registerCustomerForm[0].innerRef.current.style.borderColor =
        colors.primaryRed
    } else {
      registerCustomerForm[0].innerRef.current.style.borderColor =
        colors.borderColor
    }
    setRegisterForm({ ...registerForm, username: username, valid: valid })
  }

  const handleRegisterUser = e => {
    e.preventDefault()
    setCreate(true)
    registerCustomer(registerForm)
      .then(response => {
        setCreate(false)
        if (response.errors) {
          setRegisterError(formatErrors(response))
        } else {
          if (typeof window !== 'undefined') {
            navigate('/login')
          }
        }
      })
      .catch(err => {})
  }

  const searchOrderForm = [
    {
      value: searchUserForm.email,
      onChange: updateEmail,
      placeholder: getTranslation('email'),
      type: 'email',
      innerRef: useRef(null),
    },
    {
      value: searchUserForm.order,
      onChange: updateOrder,
      placeholder: getTranslation('order number'),
      type: 'text',
      innerRef: useRef(null),
    },
  ]

  const registerCustomerForm = [
    {
      value: registerForm.username,
      onChange: updateUsername,
      placeholder: getTranslation('username'),
      type: 'text',
      innerRef: useRef(null),
    },
    {
      value: registerForm.password,
      onChange: updatePassword,
      placeholder: getTranslation('password'),
      type: 'password',
      innerRef: useRef(null),
    },
    {
      value: registerForm.repeatpassword,
      onChange: updateRepeatPassword,
      placeholder: getTranslation('repeat password'),
      type: 'password',
      innerRef: useRef(null),
    },
  ]

  const checkForUserOrder = () => {
    return (
      <>
        <Form>
          {searchOrderForm.map((field, index) => {
            return (
              <div key={index}>
                <Form.Input {...field} />
                {index !== searchOrderForm.length - 1 ? (
                  <Divider size={SPACING.SMALL} />
                ) : (
                  ''
                )}
              </div>
            )
          })}
          <Form.Error>{orderNotFound}</Form.Error>
          <Button
            size={'large'}
            color={COLORS.PRIMARY_DARK_BLUE}
            handleClick={handleCustomerSearch}
            fullWidth={true}
            disabled={!searchUserForm.valid}
            maxWidth={maxBtnWidth}
          >
            {getTranslation('Search')}
            {searching && <Loader />}
          </Button>
        </Form>
      </>
    )
  }

  const register = () => {
    return (
      <Form>
        {registerCustomerForm.map((field, index) => {
          return (
            <div key={index}>
              <Form.Input {...field} />
              {registerCustomerForm.length !== index + 1 ? (
                <Divider size={SPACING.SMALL} />
              ) : (
                ''
              )}
            </div>
          )
        })}
        <Form.Error>{registerError}</Form.Error>
        <Button
          size={'large'}
          color={COLORS.PRIMARY_DARK_BLUE}
          handleClick={handleRegisterUser}
          fullWidth={true}
          disabled={!registerForm.valid}
          maxWidth={maxBtnWidth}
        >
          {getTranslation('Register')}
          {create && <Loader />}
        </Button>
        <Divider size={SPACING.X_SMALL} />
        <Content maxWidth={maxBtnWidth}>
          <TextLink
            to={'/login'}
            text={getTranslation('Have an account ? Login here')}
            underline={true}
          ></TextLink>
        </Content>
      </Form>
    )
  }

  return (
    <>
      <StyledGrid>
        <PageContent>
          <Heading size={2} type={'h3'} color={COLORS.PRIMARY_RED}>
            {!hasOrder ? getTranslation('Order') : getTranslation('Register')}
          </Heading>
          <Divider size={SPACING.SMALL} />
          {!hasOrder ? checkForUserOrder() : register()}
          <Divider size={SPACING.XXX_LARGE} />
          <Divider size={SPACING.XXX_LARGE} />
        </PageContent>
      </StyledGrid>
    </>
  )
}

export { Register }
