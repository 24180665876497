import React, { useEffect, useState } from 'react'
import Layout from '../views/Layout'
import SEO from '../views/Seo'
import { Register } from '../components/Register'
import { useTranslation } from '../hooks/useTranslation'
import { navigate } from 'gatsby'
import { isLoggedIn } from '../services/auth'
import { activateJwt } from '../helpers/plugins'

const CheckoutPage = () => {
  const getTranslation = useTranslation()
  const [showPage, setShowPage] = useState(false)

  useEffect(() => {
    activateJwt()
    const logged = isLoggedIn()
    if (logged) {
      if (typeof window !== 'undefined') {
        navigate('/account')
      }
    } else {
      setShowPage(true)
    }
  }, [])

  if (!showPage) {
    return ''
  }

  return (
    <Layout>
      <SEO title={getTranslation('loginPage')} />
      <Register />
    </Layout>
  )
}

export default CheckoutPage
