import styled, { css } from 'styled-components'
import { spacing, mq } from '../../styles/utilities/variables'

const StyledGrid = styled.div`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  justify-content: center;
  align-items: center;
`

const PageContent = styled.section`
  display: block;
  width: 400px;
  padding: ${spacing.xxxLarge} 0 ${spacing.xxLarge} 0;

  @media ${mq.tablet} {
    padding: ${spacing.xLarge} 0 ${spacing.xLarge} 0;
  }

  @media ${mq.mobile} {
    padding: ${spacing.medium} 0 ${spacing.medium} 0;
  }
`

const Content = styled.div`
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'none')};
  text-align: center;
  a {
    font-size: 12px;
  }
`

export { StyledGrid, PageContent, Content }
